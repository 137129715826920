import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Login from './pages/Login';
import { router } from "./rutas/rutas.js";
import { RouterProvider } from "react-router-dom";
import 'react-tooltip/dist/react-tooltip.css';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  

      <RouterProvider router={router} />
      
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

